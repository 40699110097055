:root{

  color-scheme: light;

  --cc-bg: #fff;
  --cc-primary-color: #000 !important;
  --cc-secondary-color: #000 !important;

  --cc-btn-primary-bg: #ca2017 !important;
  --cc-btn-primary-color: #fff !important;
  --cc-btn-primary-hover-bg: #ca2017 !important;
  --cc-btn-primary-hover-color: #fff;

  --cc-btn-secondary-bg: #ca2017;
  --cc-btn-secondary-color: #fff !important;
  --cc-btn-secondary-hover-bg: #ca2017 !important;
  --cc-btn-secondary-hover-color: #fff;

  --cc-cookie-category-block-bg: #ebeff9;
  --cc-cookie-category-block-border: #ebeff9;
  --cc-cookie-category-block-hover-bg: #dbe5f9;
  --cc-cookie-category-block-hover-border: #dbe5f9;
  --cc-cookie-category-expanded-block-hover-bg: #ebeff9;
  --cc-cookie-category-expanded-block-bg: #ebeff9;

  --cc-overlay-bg: rgba(219, 232, 255, 0.85)!important;

  --cc-toggle-readonly-bg: #ccc;
  --cc-toggle-on-knob-bg: var(--cc-bg);
  --cc-toggle-off-bg: #000;
  --cc-toggle-readonly-knob-bg: #ca2017;

  --cc-separator-border-color: #fff;

  --cc-footer-border-color: #000;
  --cc-footer-bg: var(--cc-bg);

  --cc-btn-border-radius: 4px 4px 4px 4px !important;
  --cc-modal-border-radius: var(--cc-btn-border-radius);
  --cc-pm-toggle-border-radius: var(--cc-btn-border-radius);
}

#cc-main .cm__btn[data-role="necessary"],
#cc-main .cm__btn[data-role="show"]{
  display: none !important;
}

#cc-main .cc-setting-link {
  text-decoration: underline !important;
  font-weight: bold !important;
}

#cc-main .pm__section--expandable .pm__section-arrow {
  background: $black-color !important;
}

#cc_main .cc-link {
  color: $black-color !important;
  text-decoration: underline !important;
  border-bottom: none !important;

  &:hover,
  &:focus {
    color: $white-color !important;
    text-decoration: underline !important;
  }
}

#cc-main #cm__title {
  display: none !important;
  color: $black-color !important;
}

#cc-main #cm__desc {
  color: $black-color !important;
}

#cc-main .pm__title{
  color: $black-color !important;
}

#cc-main .pm__desc {
  color: $black-color !important;
}

#c-p-bn {
  color: $black-color !important;

  &:hover {
    color: $white-color !important;
  }
}