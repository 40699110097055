@import "variables";
@import "cookie-consent";

body {
    font-family: "Montserrat",sans-serif;
    font-size: 22.5px;
    line-height: 1.5 !important;
    font-weight: 400;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

section {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

// Header Styles
.header {
    background-color: #fff;
    padding: 20px 10px;

    .logo a {
        font-size: 1.6em;
        font-weight: 900;
        font-style: normal;
        color: $primary-color;
        text-decoration: none;
        word-break: break-word;
        font-feature-settings: "lnum";
        letter-spacing: .01em;
        line-height: 1;
        text-transform: uppercase;
    }

    .nav-link {
        font-size: .625em;
        font-weight: 700;
        letter-spacing: .05em;
        color: $primary-color;
        font-feature-settings: "lnum";
        text-transform: uppercase;
        &:hover {
            color: $hover-color;
            transition: all .2s ease-in-out;
        }
    }

    .dropdown-menu {
        background-color: $primary-color;
        padding-top: 0;
        padding-bottom: 0;
        width: auto !important;
        min-width: 0;
        left: 50%;
        transform: translateX(-50%);

        .dropdown-item {
            font-size: .8em;
            font-weight: 700;
            letter-spacing: .05em;
            color: $white-color;
            padding: 12px 16px;
            font-feature-settings: "lnum";
            &:hover {
                background-color: $hover-color;
                transition: all .2s ease-in-out;
            }
        }

    }

    nav {
        margin-top: 20px;
    }

    .nav {
        display: flex;
        justify-content: center;
        gap: 20px;
    }

    .navbar-toggler {
        color: $primary-color !important;
        margin-bottom: 10px !important;
        border: none;
        outline: none;

        &:hover,
        &:focus {
            color: $hover-color !important;
            transition: all .2s ease-in-out;
            outline: none;
            box-shadow: none;
        }
    }

    .dropdown:hover .dropdown-menu {
        display: block;
    }

    .nav-item {
        &:hover {
            background-color: $menu-link-bg !important;
            border-radius: 4px;
            transition: all .2s ease-in-out;
            a {
                color: $white-color !important;
                transition: all .2s ease-in-out;
            }
        }
    }

    .dropdown-menu .dropdown-item:hover {
        background-color: $menu-link-bg !important;
        transition: all .2s ease-in-out;
    }

    .dropdown-menu {
        width: 100%;
        min-width: 100%;
    }
}

.content-wrapper{
    max-width: 800px;
    margin-top: 2rem!important;
    margin-bottom: 2rem!important;

    ul,ol {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin: 0.5rem 0 !important;

        li {
            line-height: 1.5;
            margin: 5px 0px 5px 0px !important;
        }
    }

    ul {
        padding-left: 1.4em;
        padding-right: 1.4em;
    }

    ol {
        padding-left: 1.7em;
        padding-right: 1.7em;
    }

    img{
        max-height: 50%
    }
}

.copyright {
    font-weight: bold;
    font-size: 0.7em;
}

p {
    font-size: inherit;
    line-height: 1.5 !important;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

footer li ,
a {
    color: $link-color;
    text-decoration: underline;
    &:hover {
        color: $link-hover-color;
        text-decoration: none;
        transition: all .2s ease-in-out;
    }
}

h1,h2, h3, h4 {
    font-feature-settings: "lnum";
    font-style: normal;
    font-weight: 900;
    word-break: break-word;
    margin: 0;
    letter-spacing: .01em;
    line-height: 1.2;
    margin: 1rem 0 !important;

}

h1 {
    font-size: 2.4em;
    text-transform: uppercase;
    text-align: center;
}
h2 { font-size: 1.4em; }
h3 { font-size: 1.25em; }
h4 { font-size: 1em; }

@media (max-width: 992px) {
    body {
        font-size: 20px;
    }

    h1 { font-size: 2.25em; }
    h2 { font-size: 1.575em; }
    h3 { font-size: 1.4em; }

    .content-wrapper{
        max-width: 800px;

        ul {
            padding-left: 1.5em;
            padding-right: 1.5em;
        }

        ol {
            padding-left: 1.7em;
            padding-right: 1.7em;
        }
    }

    .header {

        .navbar-toggler {
            margin-bottom: 20px !important;
        }

        .nav-link {
            font-size: .8em;
        }

        .nav-link.dropdown-toggle {
            border-top: 1px solid #ffffff;
            border-bottom: 1px solid #ffffff;
        }

        .dropdown-menu {
            .dropdown-item{
                font-size: .8em;
            }
        }

        .navbar{
            padding-bottom: 0 !important;
        }

        #navbarContent {
            background-color: $primary-color;
        }

        .nav-item {
            &:hover {
                background-color: transparent !important;
                border-radius: 0px;
                color: $white-color !important;
                a {
                    color: $white-color !important;
                }
            }
        }

        .nav-link {
            color: $white-color !important;
            &:hover {
                color: $white-color !important;
            }
        }

        .dropdown-menu {
            padding: 0 !important;
            left: auto;
            transform: none;
        }

        .dropdown-menu .dropdown-item:hover {
            background-color: transparent !important;
            color: $white-color !important;
            border-radius: 0px;
        }

        .dropdown-toggle::after {
            display: none !important;
        }
    }
}

@media (max-width: 600px) {
    body {
        font-size:18px;
    }

    .logo a {
        font-size: 1.4em;
    }

    h1 { font-size: 1.8em; }
    h2 { font-size: 1.5em; }
    h3 { font-size: 1.35em; }

    .content-wrapper{
        max-width: none;

        ul {
            padding-left: 1.6em;
            padding-right: 1.6em;
        }

        ol {
            padding-left: 1.8em;
            padding-right: 1.8em;
        }

    }

    .header {
        .nav-link {
            font-size: .8em;
        }

        .dropdown-menu {
            .dropdown-item{
                font-size: .8em;
            }
        }

    }
}

@media (max-width: 500px) {
    body {
        font-size:17px;
    }

    .header .logo a {
        font-size: 1.4em;
    }

    h1 { font-size: 1.8em; }
    h2 { font-size: 1.5em; }
    h3 { font-size: 1.35em; }

    .content-wrapper{
        max-width: none;

        ul {
            padding-left: 1.6em;
            padding-right: 1.6em;
        }

        ol {
            padding-left: 1.8em;
            padding-right: 1.8em;
        }
    }

    .header {
        .nav-link {
            font-size: .8em;
        }

        .dropdown-menu {
            .dropdown-item{
                font-size: .8em;
            }
        }

    }

}



